import React, { useContext, useState } from "react"
import AuthContext from "../context/AuthContext"
import { useSWRConfig } from "swr"
import { useBrands } from "../swr"
import Links from "../components/Links"
import {
  FaImage,
  FaPencilAlt,
  FaSave,
  FaTimes,
  FaWindowClose,
} from "react-icons/fa"
import Spinner from "../components/Spinner"
import {  toast } from "react-toastify"
import HandleConfirm from "../components/HandleConfirm"
import AccessDenied from "../components/AccessDenied"
import { NOIMAGE } from "../config"
import ModalDialog from "../components/ModalDialog"
import ModalImage from "../components/ModalImage"
import { saveImage } from "../handleImage"
import { Helmet } from "react-helmet-async"

export default function EditBrands() {
  const {
    user: { isAdmin },
  } = useContext(AuthContext)
  const { mutate } = useSWRConfig()

  const brandsList = useBrands()

  const initValues = {
    _id: null,
    name: "",
    image:{md:'',sm:''}  
  }
  const [values, setValues] = useState(initValues)
  const [showModal, setShowModal] = useState(false)
  const [brandForDelete, setBrandForDelete] = useState({})
  const [showImageUpload, setShowImageUpload] = useState(false)

  const handleDelete = async (item) => {
    const res = await fetch(`/api/brands/${item._id}`, {
      method: "DELETE",
    })
    const data = await res.json()
    if (!res.ok) {
      toast.error(data.message)
    }
    setValues(initValues)
    mutate(`/api/brands`)
  }

  const handleEdit = (item) => {
    setValues({
      _id: item._id,
      name: item.name,
      image:item.image   
    })
  }
  const handleModal = (item) => {
    setBrandForDelete(item)
    setShowModal(true)
  }
  const handleSave = async () => {
    if (!values.name.trim()) {
      toast.error("Название бренда обязательно")
      return
    }
    const res = await fetch(`/api/brands`, {
      method: values._id ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
    const data = await res.json()
    if (!res.ok) {
      toast.error(data.message)
    }
    setValues(initValues)

    mutate(`/api/brands`)
  }

  const handleName = (e) => {
    e.preventDefault()
    const { value } = e.target
    setValues(prev=>({ ...prev, name: value }))
  }

  const resetBrand = () => {
    setValues(initValues)
  }

  const deleteImage = () => {
    setValues(prev=>({ ...prev,image:{md:'',sm:''} }))
  }

  const handleUploadChange = async (e) => {
    const file = e.target.files[0]
    try {
      // бренды сохраняются в пропорции 1:1
      const { md, sm } = await saveImage({ file, ratio: "11" })
      setValues(prev => ({ ...prev, image: { md, sm } }))
      setShowImageUpload(false)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handle = (rez) => {
    if (rez) {
      handleDelete(brandForDelete)
    }
    setBrandForDelete({})
    setShowModal(false)
  }

  return (
    <>
      <Helmet>
        <title>Редактировать бренды</title>
        <meta
          name="description"
          content="Редактирование брендов в машазине Кармен"
        />
      </Helmet>
      {isAdmin ? (
        <div className="px-2">
          {brandsList ? (
            <>
              <Links home={true}>
                <div className="flex justify-end items-center text-3xl">
                  <FaSave
                    className="text-sb cursor-pointer hover:text-sb-dark"
                    onClick={handleSave}
                  />
                </div>
              </Links>
              <div className="pt-4 grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="max-h-[80vh] overflow-auto border border-sb rounded-md p-1">
                  {brandsList.map((item, i) => (
                    <div
                      className="px-3 py-2 flex items-center gap-3 text-lg odd:bg-gray-200 even:bg-gray-50"
                      key={i}
                    >
                      <p className="flex-1"> {item.name}</p>
                      <FaPencilAlt
                        className="text-sb cursor-pointer text-xl"
                        onClick={() => handleEdit(item)}
                      />
                      <FaTimes
                        className="text-red-500 cursor-pointer text-xl"
                        onClick={() => handleModal(item)}
                      />
                    </div>
                  ))}
                </div>
                <div>
                  <div className=" flex items-center justify-between gap-x-[10px] mb-2">
                    <input
                      type="text"
                      className="my-1 h-8 text-xl px-1 flex-1"
                      placeholder="Название бренда"
                      value={values.name}
                      onChange={handleName}
                    />
                    <FaTimes
                      className="text-3xl text-red-500 hover:cursor-pointer"
                      title="Новые опции"
                      onClick={resetBrand}
                    />
                  </div>

                  <div className="flex items-center justify-center h-full">
                    <div>
                      <div className="w-[300px] h-[300px] flex justify-center items-center border border-gray-700 p-1">
                        <img
                          className="max-w-full max-h-full"
                          src={values.image.md ? `${values.image.md}` : NOIMAGE}
                          alt=""
                        />
                      </div>

                      <div className="flex text-3xl justify-around w-full my-2">
                        <FaImage
                          className="flex cursor-pointer  text-sb  "
                          onClick={() => {
                            setShowImageUpload(true)
                          }}
                          name="save"
                          title="Сохранить"
                        />
                        <FaWindowClose
                          className="flex cursor-pointer text-red-500"
                          onClick={deleteImage}
                          name="delete"
                          title="Удалить"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Spinner />
          )}
         
          {showModal ? (
            <HandleConfirm
              title1="Удалить бренд?"
              title2={brandForDelete.name}
              handle={handle}
            />
          ) : null}
          {showImageUpload ? (
            <ModalDialog>
              <ModalImage
                setShowImageUpload={setShowImageUpload}
                handleUploadChange={handleUploadChange}
              />
            </ModalDialog>
          ) : null}
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  )
}
