import React, { useContext, useState } from "react"
import ProductsContext from "../../context/ProductsContext"
import { NOIMAGE } from "../../config"
import Loupe from "../Loupe"
import { FaSearch, FaTimes } from "react-icons/fa"
import { getCurrencySymbol, getPriceForShow } from "../../utils"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useCurrencyRate } from "../../swr"

export default function Search() {
  const [isShowList, setIsShowList] = useState(false)
  const [searchString, setSearchString] = useState("")
  const [products, setProducts] = useState([])
  const [timeoutID, setTimeoutID] = useState(0)
  const [isShowLoupe, setIsShowLoupe] = useState(false)
  const [image, setImage] = useState("")
  const { currencyShop } = useContext(ProductsContext)

  const navigate = useNavigate()

  const currencyRate = useCurrencyRate()

  const handleChange = (value) => {   
    const string = value.replace(/[^A-Za-zА-Яа-я 0-9-_]/g, "")
    setSearchString(string)
    clearTimeout(timeoutID)
    setTimeoutID(
      setTimeout(async () => {
        const res = await fetch(
          `/api/products/search?string=${string.trim()}`
        )
        const { products } = await res.json()
        if (!res.ok) {
          toast.error("Server error")
          return
        }

        setProducts(products)
      }, 200)
    )
  }

  return (
    <div
      className="w-full  relative"
      tabIndex={0}
      onFocus={() => setIsShowList(true)}
      onBlur={() => setIsShowList(false)}
    >
      <input
        type="text"
        value={searchString}
        onChange={e=>handleChange(e.target.value)}
        maxLength="40"
        title="Поиск по модели"
        className="w-full h-[30px] text-base"
      />
      {searchString ? (
        <FaTimes
          className="absolute top-1/2 right-0 -translate-x-1/2 -translate-y-1/2 text-red-500 text-xl cursor-pointer"
          onClick={() => handleChange("")}
        />
      ) : (
        <FaSearch className="absolute top-1/2 right-0 -translate-x-1/2 -translate-y-1/2 text-sb" />
      )}

      {!!products.length && (
        <div
          className={`absolute z-50 top-[110%] left-0 text-[18px] bg-[#f8f9f9] w-full
              p-1 border border-[#999] rounded-md shadow-md ${isShowList ? "block" : "hidden"
            }`}
        >
          {products.map((item, i) => (
            <div
              onClick={() => {
                setIsShowList(false)
                navigate(`/product/${item._id}`)
              }}
              key={i}
              className="flex items-center py-[2px] border border-[#ccc] cursor-pointer"
            >
              <div>
                <img
                  className="max-w-[60px] max-h-[80px] border border-[#888] p-[2px] cursor-zoom-in"
                  src={
                    item.images[0].sm
                      ? `${item.images[0].sm}`
                      : `${NOIMAGE}`
                  }
                  alt=''
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsShowLoupe(true)
                    setImage(item.images[0].lg)
                  }}
                />
              </div>
              <div className="flex items-center justify-center flex-col w-full">
                <div className="text-gray-900 text-lg">{item.name}</div>

                <div className="text-[#784212]">
                  {!!currencyRate && (
                    <>
                      {getPriceForShow({
                        currencyShop,
                        currencyRate,
                        product: item,
                      }) +
                        " " +
                        getCurrencySymbol(currencyShop)}
                    </>
                  ) }
                </div>
              </div>
            </div>
          ))}
          <div className="italic text-[#888] px-0 py-[2px]">
            <p>Показаны результаты поиска первых 10 товаров</p>{" "}
          </div>
        </div>
      )}
      {isShowLoupe ? <Loupe setIsShow={setIsShowLoupe} image={image} /> : null}
    </div>
  )
}
