import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import {
  useBrands, 
  useNavbarCatalogs, 
} from "../swr"
import WaveContainer from "./WaveContainer"
import {
  LOGO_KS,
  LOGO_VF,
  NAVBAR_IMAGE,
  NOIMAGE,
  PHONE1,
  PHONE2,
  PHONE3,
} from "../config"
import BrandContext from "../context/BrandContext"
import {
  FaCaretDown,
  FaCircle,
  FaFilter,
  FaRegSquare,
  FaSquare,
  FaThLarge,
  FaTimes,
} from "react-icons/fa"

const isExistBrand = (brand, arr) => {
  return arr.some((item) => item._id === brand._id)
}

export default function Navbar() {
  const { brands, setBrands } = useContext(BrandContext)

  const [showCatalogs, setShowCatalogs] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [windowWidth, setWindowWidth] = useState(0)
  const [catalogsList, setCatalogsList] = useState([])
  const [brandsList, setBrandsList] = useState([])
 

  const navigate = useNavigate()

  const catalogs=useNavbarCatalogs(brands)
  const brandsHook = useBrands()
  

  // предотвращение скачков при изменении хуков SWR с/на null
  useEffect(() => {
    if (brandsHook) {
      setBrandsList(brandsHook)
    }
  }, [brandsHook])

  useEffect(() => {
    if (catalogs) {
      setCatalogsList(catalogs)
    }
  }, [catalogs])

 
  // -----------------------------

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  const handleShowCatalogs = () => {
    if (showFilter) {
      setShowFilter(false)
      setShowCatalogs(true)
    } else {
      setShowCatalogs((prev) => !prev)
    }
  }

  const handleShowFilter = () => {
    if (showCatalogs) {
      setShowCatalogs(false)
      setShowFilter(true)
    } else {
      setShowFilter((prev) => !prev)
    }
  }

  const handleBrandClick = (item) => {
    if (isExistBrand(item, brands)) {
      setBrands((prev) => [...prev.filter((elem) => elem._id !== item._id)])
    } else {
      setBrands((prev) => [...prev, item])
    }
  }

  const resetBrand = () => {
    setBrands([])
  }

  const handleCatalogClick = (item) => {
    navigate(`/catalog/${item.slug}/${item._id}`)
    if (windowWidth < 1024) {
      setTimeout(() => setShowCatalogs(false), 500)
    }
  }

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
 
  return (
    <div
      className={`w-full lg:h-full  flex flex-col gap-2 relative border border-gray-300  rounded-md`}
    >
      <div className="flex justify-between items-center p-2">
        <div
          className="text-lg text-gray-50 px-5 py-2 border rounded-lg bg-sb
        cursor-pointer hover:bg-sb-dark"
        >
          <div className="flex items-center gap-4" onClick={handleShowCatalogs}>
            <FaThLarge />
            <h2>Каталоги</h2>
          </div>
        </div>
        <div
          className="text-lg text-gray-50 p-3 rounded-lg  bg-sb
        cursor-pointer hover:bg-sb-dark"
          onClick={handleShowFilter}
        >
          <FaFilter />
          {!!brands.length && (
            <FaCircle className="text-red-500 text-[12px] absolute top-[6px] right-[6px]" />
          )}
        </div>
      </div>
      <div className="hidden lg:block w-full h-full   border border-t-gray-300 rounded-md">
        <div className="p-2 flex flex-col gap-4 ">
          <img
            className="max-w-full border border-gray-500 p-1 rounded-md"
            src={NAVBAR_IMAGE}
            alt="магазин"
          />

          <div className="text-xl/8 p-2 border border-gray-500 rounded-md text-amber-800">
            <h4 className="indent-4">
              Xарьков, ТЦ Барабашово, стоянка "Болото", BK-512
            </h4>
          </div>
          <div className="text-2xl p-2 border border-gray-500 rounded-md text-amber-800">
            <div className="flex items-center gap-3 p-1">
              <img src={LOGO_VF} className="max-w-6" alt="" />
              <p>{PHONE1}</p>
            </div>

            <div className="flex items-center gap-3 p-1">
              <img src={LOGO_KS} className="max-w-6" alt="" />
              <p>{PHONE2}</p>
            </div>

            <div className="flex items-center gap-3 p-1">
              <img src={LOGO_VF} className="max-w-6" alt="" />
              <p>{PHONE3}</p>
            </div>
          </div>
        </div>
      </div>

      {!!brandsList && (
        <div
          className={` w-full h-[700px]  top-[70px] left-0 bg-gray-50  absolute  z-40 lg:h-auto lg:bottom-0 transition-all duration-200  origin-top
         flex flex-col  items-center gap-2 border border-sb rounded-md p-3  overflow-auto ${
           showFilter ? "scale-y-100 opacity-100" : "scale-y-0 opacity-0"
         }`}
        >
          <div className="w-full flex py-2 px-1 items-center justify-between gap-2">
            <div className="text-2xl flex items-center justify-start gap-1 font-bold text-[#78350F]">
              <p>Бренд</p> <FaCaretDown />
            </div>

            <div
              className={`text-2xl text-red-500 cursor-pointer ${
                !brands.length && "opacity-40 pointer-events-none"
              }`}
              onClick={resetBrand}
            >
              <FaTimes />
            </div>
          </div>

          {brandsList.map((item, i) => (
            <div className="w-full  hover:cursor-pointer" key={i}>
              <div
                className="flex justify-start items-center gap-3 text-gray-800  border border-blue-400 
                      flex-nowrap  py-2 px-3 rounded-md hover:bg-gray-200 group transition-all duration-200"
                onClick={(e) => handleBrandClick(item)}
              >
                {isExistBrand(item, brands) ? (
                  <FaSquare className="text-2xl text-sb" />
                ) : (
                  <FaRegSquare className="text-2xl text-gray-700 opacity-30 group-hover:opacity-100 transition-all duration-200" />
                )}

                <div className="text-xl flex items-center justify-center gap-2 ">
                  <p>{item.name}</p>
                  <p className="text-gray-500">({item.qnt})</p>{" "}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {!!catalogsList && (
        <div
          className={`w-full h-[700px]  top-[70px] left-0 bg-gray-50  absolute  z-30 lg:h-auto lg:bottom-0 transition-all duration-200 origin-top
         flex flex-col gap-2 items-center  border border-sb rounded-md p-3  overflow-auto ${
           showCatalogs ? "scale-y-100 opacity-100" : "scale-y-0 opacity-0"
         }`}
        >
          {catalogsList.map((item, i) => (
            <div className="w-full  hover:cursor-pointer" key={i}>
              <WaveContainer>
                <div
                  className="flex justify-start items-center gap-1 text-gray-800  border border-blue-600 
                      flex-nowrap  p-2 rounded-md shadow-md shadow-blue-600/50"
                  onClick={(e) => handleCatalogClick(item)}
                >
                  <div className="w-[40px] h-[30px]  flex items-center justify-center">
                    <img
                      className="max-w-full max-h-full"
                      src={item.image.sm ? `${item.image.sm}` : NOIMAGE}
                      alt=""
                    />
                  </div>
                  <div className="flex items-center justify-start gap-2 text-xl">
                    <h4>{item.name}</h4>{" "}
                    <p className="text-gray-500">
                      ({item.qnt})
                    </p>
                  </div>
                </div>
              </WaveContainer>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
