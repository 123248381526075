import { NOIMAGE } from "../config"
import { createPortal } from "react-dom"
import { FaTimes } from "react-icons/fa"

export default function Loupe({ image, setIsShow }) {
  const content = (
    <div className="z-[150] w-full h-full fixed top-0 left-o bg-[rgb(0,0,0,0.9)]">
      <div className="flex justify-center items-center w-full relative h-full">
        <img
          className="max-w-full max-h-full cursor-pointer"
          onClick={(e) => {
            e.stopPropagation()
            setIsShow(false)
          }}
          src={image ? `${image}` : `${NOIMAGE}`}
          alt=""
        />
      </div>

      <div
        onClick={(e) => {
          e.stopPropagation()
          setIsShow(false)
        }}
        className="text-white absolute top-3 right-3 text-3xl hover:cursor-pointer
         bg-gray-900 rounded-full p-1 opacity-50"
      >
        <FaTimes />
      </div>
    </div>
  )

  return createPortal(content, document.body)
}
