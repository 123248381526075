import { NOIMAGE } from "../config/index"
import {
  getCurrencySymbol,
  getPriceForShow,
  getShortDescription,
  localStorageService,
} from "../utils/index"
import { useContext, useEffect, useState } from "react"
import ProductsContext from "../context/ProductsContext"

import Loupe from "./Loupe"
import { useNavigate } from "react-router-dom"
import { useCurrencyRate, useProductsByCatalogId } from "../swr"
import Pagination from "./Pagination"
import Spinner from "./Spinner"

export default function ProductsList({ isShowAsList, id, brands, isForsed }) {
  const { currencyShop } = useContext(ProductsContext)

  const [isShow, setIsShow] = useState(false)
  const [image, setImage] = useState("")
  const [innerWidth, setInnerWidth] = useState(0)
  const [productList,setProductList]=useState([])
  const [currentPage, setCurrentPage] = useState(() => {
    const data = localStorageService.get("catalog")
    return data && data[id] ? data[id] : 1
  })

  const navigate = useNavigate()
  useEffect(() => {
    setInnerWidth(window.innerWidth)
  }, [])

  const currencyRate = useCurrencyRate()

  const products = useProductsByCatalogId({
    id,
    brands,
    isForsed,
  })

  useEffect(() => {
    if (products) {
      setProductList(products.sort((a, b) => (a.name > b.name ? 1 : -1)))
    }
  },[products])

  

  // pagination
  const productsPerPage = 12
  const idxOfLastProduct = currentPage * productsPerPage
  const idxOfFirstProduct = idxOfLastProduct - productsPerPage
  const productsOnPage = productList.slice(idxOfFirstProduct, idxOfLastProduct)

  const numbers = []
  for (let i = 1; i <= Math.ceil(productList.length / productsPerPage); i++) {
    numbers.push(i)
  }

  useEffect(() => {
    let data = localStorageService.get("catalog")
    // предохранитель если окажется что номер в localStorage больше чем есть страниц
    if (data && data[id] && productList.length && numbers.length < data[id]) {
      data = localStorageService.delete("catalog", id)
    }
    setCurrentPage(data && data[id] ? data[id] : 1)
  }, [id, productList.length, numbers.length])

  const handlePageClick = (item) => {
    if (!id) return
    localStorageService.update("catalog", { [id]: item })
    setCurrentPage(item)
  }

  return (
    <>
      {/* Спинер круттся тогда когда products=null и productList пустой */}
      {!!products || !!productList.length ? (  
        <>
          {isShowAsList ? (
            <div className="px-1">
              <table className="w-full border-collapse table-fixed text-base border border-gray-500">
                <thead className="border border-gray-500">
                  <tr>
                    <th className="w-[70px]">&nbsp;</th>
                    <th className="w-[180px]">Название</th>

                    <th className="w-auto hidden lg:table-cell">Описание</th>
                    <th className="w-[100px]">Цена</th>
                  </tr>
                </thead>
                <tbody className="border border-gray-500">
                  {productsOnPage.map((item, i) => (
                    <tr
                      className="border border-gray-500 hover:border-x-blue-500 hover:border-x
                     hover:cursor-pointer hover:bg-gray-50"
                      onClick={() =>
                        navigate(`/product/${item.slug}/${item._id}`)
                      }
                      key={i}
                    >
                      <td className=" text-center align-middle leading-4 border border-gray-500 ">
                        <div
                          className=" inline-flex justify-center items-center p-0.5
                         h-[85px] w-[65px]"
                        >
                          <img
                            className="max-w-full max-h-full cursor-zoom-in active:scale-95"
                            src={
                              item.images.length
                                ? `${item.images[0].sm}`
                                : `${NOIMAGE}`
                            }
                            alt={item.name}
                            onClick={
                              item.images.length
                                ? (e) => {
                                    e.stopPropagation()
                                    setIsShow(true)
                                    setImage(item.images[0].lg)
                                  }
                                : (e) => e.stopPropagation()
                            }
                          />
                        </div>
                      </td>
                      <td className="text-center border border-gray-500 px-1">
                        <h3>{item.name}</h3>
                      </td>

                      <td className="text-center border border-gray-500 px-1 hidden lg:table-cell">
                        {getShortDescription(item.description, innerWidth / 15)}
                      </td>
                      <td className="text-center text-xl border border-gray-500 px-1">
                        {!!currencyRate && (
                          <div>
                            {getPriceForShow({
                              currencyRate,
                              currencyShop,
                              product: item,
                            })}{" "}
                            {getCurrencySymbol(currencyShop)}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {isShow ? <Loupe image={image} setIsShow={setIsShow} /> : null}
            </div>
          ) : (
            <div className="grid gap-1 grid-cols-2 auto-rows-auto sm_1:auto-rows-[520px]   sm_1:grid-cols-[repeat(auto-fill,minmax(300px,1fr))] sm_1:gap-5 justify-items-center">
              {productsOnPage.map((item, i) => (
                <div
                  // className="flex flex-col gap-1 pt-1 items-center border border-gray-500 shadow-md w-[300px] h-[470px]
                  //  overflow-auto hover:cursor-pointer hover:border-blue-500"
                  className="grid grid-cols-[minmax(0,300px)] grid-rows-[minmax(0,1fr),auto,40px] sm_1:grid-rows-[minmax(0,1fr),minmax(50px,auto),50px]  border border-gray-500 shadow-md
               hover:cursor-pointer hover:border-blue-500"
                  onClick={() => navigate(`/product/${item.slug}/${item._id}`)}
                  key={i}
                >
                  <div className="p-1 flex items-center justify-center ">
                    <img
                      className="max-w-full max-h-full  hover:cursor-zoom-in active:scale-95"
                      src={
                        item.images.length
                          ? `${item.images[0].md}`
                          : `${NOIMAGE}`
                      }
                      alt={item.name}
                      onClick={
                        item.images.length
                          ? (e) => {
                              e.stopPropagation()
                              setIsShow(true)
                              setImage(item.images[0].lg)
                            }
                          : (e) => e.stopPropagation()
                      }
                    />
                  </div>
                  <h3 className="flex  items-center justify-center  border border-y-gray-500 text-gray-800  text-lg/5 sm_1:text-xl/6 p-1 indent-1 hyphens-auto">
                    {item.name}
                  </h3>

                  <div className="  flex justify-center items-center bg-gray-200 text-xl sm_1:text-2xl ">
                    {currencyRate ? (
                      <p>
                        {getPriceForShow({
                          currencyRate,
                          currencyShop,
                          product: item,
                        })}{" "}
                        {getCurrencySymbol(currencyShop)}
                      </p>
                    ) : (
                      <p> </p>
                    )}
                  </div>
                </div>
              ))}

              {isShow ? <Loupe image={image} setIsShow={setIsShow} /> : null}
            </div>
          )}
          <Pagination
            currentPage={currentPage}
            handlePageClick={handlePageClick}
            numbers={numbers}
          />
        </>
      ) : (
        <Spinner />
      )}
    </>
  )
}
