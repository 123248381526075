import AccessDenied from "../components/AccessDenied"
import AuthContext from "../context/AuthContext"
import { useContext, useState, useEffect } from "react"
import {  toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useLoaderData, useNavigate } from "react-router-dom"
import { createPriceObject, idToString } from "../utils/index"
import SelectOptions from "../components/SelectOptions"
import Links from "../components/Links"
import ModalImage from "../components/ModalImage"

import {
  FaArrowAltCircleRight,
  FaArrowCircleLeft,
  FaCircle,
  FaCloudDownloadAlt,
  FaPlusSquare,
  FaSave,
  FaTimes,
  FaWindowClose,
} from "react-icons/fa"

import ModalDialog from "../components/ModalDialog"
import { useBrands, useCatalogs, useOptions } from "../swr"

import { saveImage } from "../handleImage"
import { Helmet } from "react-helmet-async"
import AdminPrice from "../components/AdminPrice"
import { useSWRConfig } from "swr"
import BrandContext from "../context/BrandContext"

export default function EditProduct() {
  const {
    user: { isAdmin },
  } = useContext(AuthContext)

  const { mutate } = useSWRConfig()
  const { brands: brandsContext } = useContext(BrandContext)
  const product = useLoaderData() // with populate!!!

  const navigate = useNavigate()

  const brands = useBrands()
  const catalogs = useCatalogs()

  const options = useOptions()

  const [values, setValues] = useState({
    _id: product._id,
    name: product.name,
    description: product.description,
    brandId: product.brandId._id,
    catalogId: product.catalogId._id,
    optionsId: product.optionsId._id,
    ownOptions: product.ownOptions,
    optionValues: product.optionValues,
    isShowcase: product.isShowcase,
    currencyValue: product.currencyValue,
  })

  const [images, setImages] = useState(product.images)
  const [catalogName, setCatalogName] = useState(product.catalogId.name)
  const [brandName, setBrandName] = useState(product.brandId.name)
  const [optionsName, setOptionsName] = useState(product.optionsId.name)
  const [imageIdx, setImageIdx] = useState(0)
  const [showImageUpload, setShowImageUpload] = useState(false)

  const listForCatalogMenu = catalogs
    ? catalogs.sort((a, b) => (a.tree > b.tree ? 1 : -1))
    : []

  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      optionValues: createPriceObject({
        ownOptions: values.ownOptions,
        optionValues: values.optionValues,
      }),
    }))
  }, [values.ownOptions])

  const resetBrand = () => {
    setBrandName("")
    setValues((prev) => ({ ...prev, brandId: null }))
  }

  const resetCatalog = () => {
    setCatalogName("")
    setValues((prev) => ({ ...prev, catalogId: null }))
  }

  const resetOptions = () => {
    setOptionsName("")
    setValues((prev) => ({ ...prev, optionsId: null, ownOptions: {} }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // Проверка на заполнение поля имени и модели товара
    const hasEmptyFields = !values.name.trim()

    if (hasEmptyFields) {
      toast.error("Поле Название должно быть заполнено")
      return
    }

    if (!values.brandId) {
      toast.error("Поле бренда является обязательным")
      return
    }
    if (!values.catalogId) {
      toast.error("Поле каталога является обязательным")
      return
    }

    // Send data
    const res = await fetch(`/api/products`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...values, images }),
    })
    const data = await res.json()

    if (!res.ok) {
      toast.error(data.message)
    } else {
      const brandIds = brandsContext.map((item) => item._id)
      mutate({ url: `/api/catalogs/navbar`, swrData: { brandIds } })
      navigate(-2)
    }
  }

  const handleChange = (e) => {
    const { name, value, checked } = e.target
    if (name === "isShowcase" || name === "isInStock") {
      setValues((prev) => ({ ...prev, [name]: checked }))
    } else {
      e.preventDefault()
      // ввод названия товара
      setValues((prev) => ({ ...prev, [name]: value }))
    }
  }
  const handleOptionsClick = (item) => {
    setOptionsName(item.name)
    const ownOptions = Object.assign(
      {},
      ...Object.keys(item.options).map((option) => ({ [option]: [] }))
    )
    setValues((prev) => ({ ...prev, optionsId: item._id, ownOptions }))
  }

  const handleClickBrand = (item) => {
    setValues((prev) => ({ ...prev, brandId: item._id }))
    setBrandName(item.name)
  }

  const handleClickCatalog = (item) => {
    //  проверка на подкаталоги
    if (!item.isLeaf) {
      toast.warning(
        "В выбранной каталогк есть подкаталоги. Выберите другой каталог"
      )
      return
    }
    setValues((prev) => ({ ...prev, catalogId: item._id }))
    setCatalogName(item.name)
  }

  const handleUploadChange = async (e) => {
    const file = e.target.files[0]
    try {
      const { lg, md, sm } = await saveImage({ file })
      // что нажато: добавление новой картинки или изменение существующей
      if (images[imageIdx]) {
        setImages(
          images.map((item, i) => (i === imageIdx ? { lg, md, sm } : item))
        )
      } else {
        setImages([...images, { lg, md, sm }])
      }

      setShowImageUpload(false)
    } catch (error) {
      toast.error(error.message)
    }
  }
  const deleteImage = (i) => {
    setImages(images.filter((item, idx) => idx !== i))
  }

  const handleImageLeft = (i) => {
    if (i === 0) return
    const newImages = [...images]
    newImages[i] = images[i - 1]
    newImages[i - 1] = images[i]
    setImages(newImages)
  }
  const handleImageRight = (i) => {
    if (i === images.length - 1) return
    const newImages = [...images]
    newImages[i] = images[i + 1]
    newImages[i + 1] = images[i]
    setImages(newImages)
  }

  return (
    <>
      <Helmet>
        <title>Редактировать товар</title>
        <meta
          name="description"
          content="Редактирование товара в машазине Кармен"
        />
      </Helmet>
      {!isAdmin ? (
        <AccessDenied />
      ) : (
        <div>
          <div className="px-2">
            <form>
              <Links home={true} back={true}>
                <div className="flex justify-end items-center text-3xl">
                  <FaSave
                    className="text-sb cursor-pointer hover:text-sb-dark"
                    title="Сохранить"
                    name="save"
                    onClick={handleSubmit}
                  />
                </div>
              </Links>
            
              <div className="grid grid-cols-[1fr_1fr_150px] gap-x-5 gap-y-3 py-4">
                <div>
                  <label className="text-lg block" htmlFor="name">
                    Название
                  </label>
                  <input
                    className="block w-full h-8 px-1 py-2 text-lg"
                    type="text"
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className="text-lg block" htmlFor="options">
                    Опции
                  </label>
                  <div className="relative group/options">
                    <input
                      className="block w-full h-8 px-1 py-2 text-lg"
                      type="text"
                      id="options"
                      name="options"
                      value={optionsName}
                      readOnly
                    />
                    <div
                      className="block -translate-x-1/2 -translate-y-1/2 text-xl
                     absolute text-red-500 top-1/2 right-0 cursor-pointer"
                      onClick={resetOptions}
                    >
                      <FaTimes />
                    </div>

                    <ul className="droplist_wrapper  group-hover/options:block">
                      {options && (
                        <div className="droplist">
                          {options.map((item, i) => (
                            <li
                              className={`px-2 cursor-pointer hover:bg-gray-100 
                                ${
                                  idToString(item._id) ===
                                  idToString(values.optionsId)
                                    ? "bg-gray-200"
                                    : ""
                                }`}
                              key={i}
                              onClick={() => handleOptionsClick(item)}
                            >
                              {item.name}
                            </li>
                          ))}
                        </div>
                      )}
                    </ul>
                  </div>
                </div>

                <div
                  className="relative justify-self-center text-lg w-full cursor-pointer
                 bg-gray-50 border rounded-md border-gray-700 group/currency self-end"
                >
                  <p className="text-center p-0.5">{values.currencyValue}</p>
                  <ul className="droplist_wrapper group-hover/currency:block">
                    <div className="droplist">
                      <li
                        className="px-2 cursor-pointer hover:bg-gray-100"
                        onClick={() =>
                          setValues({ ...values, currencyValue: "UAH" })
                        }
                      >
                        UAH
                      </li>
                      <li
                        className="px-2 cursor-pointer hover:bg-gray-100"
                        onClick={() =>
                          setValues({ ...values, currencyValue: "USD" })
                        }
                      >
                        USD
                      </li>
                      <li
                        className="px-2 cursor-pointer hover:bg-gray-100"
                        onClick={() =>
                          setValues({ ...values, currencyValue: "EUR" })
                        }
                      >
                        EUR
                      </li>
                    </div>
                  </ul>
                </div>

                <div>
                  <label className="text-lg block" htmlFor="category">
                    Бренд
                  </label>
                  <div className="relative group/category">
                    <input
                      className="block w-full h-8 px-1 py-2 text-lg"
                      type="text"
                      id="category"
                      name="category"
                      value={brandName}
                      readOnly
                    />
                    <div
                      className="block -translate-x-1/2 -translate-y-1/2 text-xl
                     absolute text-red-500 top-1/2 right-0 cursor-pointer"
                      onClick={resetBrand}
                    >
                      <FaTimes />
                    </div>

                    <ul
                      className="droplist_wrapper
                    group-hover/category:block"
                    >
                      {brands && (
                        <div className="droplist">
                          {brands.map((item, i) => (
                            <li
                              className={`px-2 cursor-pointer hover:bg-gray-100 
                                ${
                                  idToString(item._id) ===
                                  idToString(values.brandId)
                                    ? "bg-gray-200"
                                    : ""
                                }`}
                              key={i}
                              onClick={() => handleClickBrand(item)}
                            >
                              {item.name}
                            </li>
                          ))}
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
                <div>
                  <label className="text-lg block" htmlFor="catalog">
                    Каталог
                  </label>
                  <div className="relative group/catalog">
                    <input
                      className="block w-full h-8 px-1 py-2 text-lg"
                      type="text"
                      id="catalog"
                      name="catalog"
                      value={catalogName}
                      readOnly
                    />
                    <div
                      className="block -translate-x-1/2 -translate-y-1/2 text-xl
                     absolute text-red-500 top-1/2 right-0 cursor-pointer"
                      onClick={resetCatalog}
                    >
                      <FaTimes />
                    </div>

                    <ul
                      className="droplist_wrapper
                    group-hover/catalog:block"
                    >
                      {listForCatalogMenu && (
                        <div className="droplist">
                          {listForCatalogMenu.map((item, i) => (
                            <li
                              className={`px-2 flex items-center gap-2 cursor-pointer hover:bg-gray-200 
                                ${
                                  idToString(item._id) ===
                                  idToString(values.catalogId)
                                    ? "bg-gray-200"
                                    : ""
                                }
                                ${!item.isLeaf ? "pointer-events-none" : ""}
                                `}
                              key={i}
                              onClick={() => handleClickCatalog(item)}
                            >
                              <p>{item.tree}</p>
                              {!item.isLeaf && (
                                <FaCircle className="text-xs text-red-500" />
                              )}
                            </li>
                          ))}
                        </div>
                      )}
                    </ul>
                  </div>
                </div>

                <div className="place-self-end justify-self-stretch">
                  <input
                    className="appearance-none outline-none peer "
                    type="checkbox"
                    name="isShowcase"
                    checked={values.isShowcase}
                    id="isShowcase"
                    onChange={handleChange}
                  />
                  <label
                    className=" text-lg border border-gray-700 rounded-md  w-full
                     h-8 text-center inline-block bg-gray-50 peer-checked:bg-emerald-700
                    cursor-pointer peer-checked:text-gray-50 "
                    htmlFor="isShowcase"
                  >
                    На витрине
                  </label>
                </div>
              </div>
              {values.optionsId ? (
                <SelectOptions values={values} setValues={setValues} />
              ) : null}

              <AdminPrice values={values} setValues={setValues} />
              <div>
                <label className="text-lg block" htmlFor="description">
                  Описание
                </label>
                <textarea
                  className="block w-full h-[100px] px-2  text-lg indent-6"
                  type="text"
                  id="description"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                ></textarea>
              </div>
            </form>
            <div className="py-2">
              <h2>Изображения</h2>
              <div className="flex flex-row justify-start flex-wrap items-center gap-x-3">
                {images.length
                  ? images.map((item, i) => (
                      <div
                        className="flex flex-col items-start my-2 w-[200px]"
                        key={i}
                      >
                        <div
                          className="w-[200px] h-[250px] flex justify-center items-center border 
                        border-gray-700 p-1 "
                        >
                          <img
                            className="max-w-full max-h-full"
                            src={`${item.md}`}
                            alt=""
                          />
                        </div>
                        <div className="flex justify-around w-full my-2">
                          <FaArrowCircleLeft
                            className=" text-3xl cursor-pointer text-gray-400"
                            name="left"
                            title="Сдвинуть влево"
                            onClick={() => handleImageLeft(i)}
                          />
                          <FaArrowAltCircleRight
                            className=" text-3xl cursor-pointer text-gray-400"
                            name="right"
                            title="Сдвинуть вправо"
                            onClick={() => handleImageRight(i)}
                          />
                          <FaCloudDownloadAlt
                            className=" text-3xl cursor-pointer text-sb"
                            name="download"
                            title="Загрузить"
                            onClick={() => {
                              setImageIdx(i)
                              setShowImageUpload(true)
                            }}
                          />
                          <FaWindowClose
                            className=" text-3xl cursor-pointer text-red-500"
                            name="delete"
                            title="Удалить"
                            onClick={() => deleteImage(i)}
                          />
                        </div>
                      </div>
                    ))
                  : null}
                <FaPlusSquare
                  className="text-3xl cursor-pointer text-sb"
                  onClick={() => {
                    setImageIdx(images.length)

                    setShowImageUpload(true)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {showImageUpload ? (
        <ModalDialog>
          <ModalImage
            handleUploadChange={handleUploadChange}
            setShowImageUpload={setShowImageUpload}
          />
        </ModalDialog>
      ) : null}
    </>
  )
}
