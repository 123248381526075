import { useState } from "react"
import { createPortal } from "react-dom"
import { FaChevronLeft, FaChevronRight, FaTimes } from "react-icons/fa"

export default function Slider({
  images,
  setSliderValues,
  sliderValues,
  setMainImageIdx,
}) {
  const [num, setNum] = useState(sliderValues.idx)

  const lastNumber = images.length - 1
  const prevImage = () => {
    if (num === 0) {
      setNum(lastNumber)
    } else {
      setNum(num - 1)
    }
  }

  const nextImage = () => {
    if (num === lastNumber) {
      setNum(0)
    } else {
      setNum(num + 1)
    }
  }

  const closeSlider = (idx) => {
    setMainImageIdx(idx)
    setSliderValues((prev) => ({ ...prev, isShow: false }))
  }

  const content = (
    <div className="z-[150] w-full h-full fixed top-0 left-o bg-[rgb(0,0,0,0.9)]">
      <div className="flex justify-center items-center w-full relative h-full">
        {!!lastNumber && (
          <div
            className="absolute top-[50vh] -translate-x-1/2 -translate-y-1/2 left-4 text-2xl text-gray-50
           bg-gray-900 rounded-full p-1 opacity-50 hover:cursor-pointer flex justify-center items-center"
            onClick={prevImage}
          >
            <FaChevronLeft />
          </div>
        )}
        <img
          className="max-w-full max-h-full"
          onClick={() => closeSlider(num)}
          src={images[num].lg}
          alt=""
        />
        {!!lastNumber && (
          <div
            className="absolute top-[50vh] translate-x-1/2 -translate-y-1/2 right-4 text-2xl text-gray-50
           bg-gray-900 rounded-full p-1 opacity-50 hover:cursor-pointer flex justify-center items-center "
            onClick={nextImage}
          >
            <FaChevronRight />
          </div>
        )}
      </div>
      <div
        onClick={() => closeSlider(num)}
        className="text-gray-50 absolute top-3 right-3 text-3xl hover:cursor-pointer
         bg-gray-900 rounded-full p-1 opacity-50"
      >
        <FaTimes />
      </div>
    </div>
  )

  return createPortal(content, document.body)
}
