import AccessDenied from "../components/AccessDenied"
import Links from "../components/Links"
import AuthContext from "../context/AuthContext"
import { useNavigate } from "react-router-dom"
import React, { useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useInformation } from "../swr"
import { FaSave } from "react-icons/fa"
import { Helmet } from "react-helmet-async"

export default function EditInformation() {
  const {
    user: { isAdmin},
  } = useContext(AuthContext)

  const navigate = useNavigate()

  const information = useInformation()
  const [values, setValues] = useState({})

  useEffect(() => {
    if (!information) return

    setValues({ ...information })
  }, [information])

  const changeHandler = (e) => {
    e.preventDefault()
    setValues(prev=>({ ...prev, [e.target.name]: e.target.value }))
  }

  const submitHandler = async () => {
    const res = await fetch(`/api/information`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"        
      },
      body: JSON.stringify(values),
    })
    const data = res.json()
    if (!res.ok) {
      toast.error(data.message)
    }
    navigate("/")
  }

  return (
    <>
      <Helmet>
        <title>Редактировать страницу информации</title>
        <meta
          name="description"
          content="Редактирование страницы информации в машазине Кармен"
        />
      </Helmet>
    
      {isAdmin ? (
        <div className="px-2">
          <Links home={true} back={true}>
            <div className="flex justify-end items-center">
              <div
                className="text-3xl text-sb cursor-pointer hover:text-sb-dark"
                onClick={submitHandler}
              >
                <FaSave />
              </div>
            </div>
          </Links>

          <div className="pt-4">
            <label className="block text-2xl" htmlFor="aboutUs">
              О нас
            </label>
            <textarea
              className="p-1 w-full min-h-[100px] text-lg indent-6"
              name="aboutUs"
              id="aboutUs"
              onChange={changeHandler}
              value={values.aboutUs}
            ></textarea>
            <label className="block text-2xl" htmlFor="conditions">
              Условия сотрудничества
            </label>
            <textarea
              className="p-1 w-full min-h-[100px] text-lg indent-6"
              id="conditions"
              name="conditions"
              onChange={changeHandler}
              value={values.conditions}
            ></textarea>
            <label className="block text-2xl" htmlFor="productReturn">
              Возврат товара
            </label>
            <textarea
              className="p-1 w-full min-h-[100px] text-lg indent-6"
              id="productReturn"
              name="productReturn"
              onChange={changeHandler}
              value={values.productReturn}
            ></textarea>
            <label className="block text-2xl" htmlFor="delivery">
              Доставка
            </label>
            <textarea
              className="p-1 w-full min-h-[100px] text-lg indent-6"
              id="delivery"
              name="delivery"
              onChange={changeHandler}
              value={values.delivery}
            ></textarea>
            <label className="block text-2xl" htmlFor="address">
              Адрес
            </label>
            <textarea
              className="p-1 w-full min-h-[100px] text-lg indent-6"
              id="address"
              name="address"
              onChange={changeHandler}
              value={values.address}
            ></textarea>
            <label className="block text-2xl" htmlFor="workingHours">
              Часы работы
            </label>
            <textarea
              className="p-1 w-full min-h-[100px] text-lg indent-6"
              id="workingHours"
              name="workingHours"
              onChange={changeHandler}
              value={values.workingHours}
            ></textarea>
            <label className="block text-2xl" htmlFor="payment">
              Цены и оплата
            </label>
            <textarea
              className="p-1 w-full min-h-[100px] text-lg indent-6"
              id="payment"
              name="payment"
              onChange={changeHandler}
              value={values.payment}
            ></textarea>
          </div>
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  )
}
