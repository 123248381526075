import React, { useEffect, useState } from "react"
import { FaEllipsis } from "react-icons/fa6"

export default function Pagination({ handlePageClick, currentPage, numbers }) {
  const [visiblePages, setVisiblePages] = useState(3)

  const handleResize = () => {
    const windowWidth = window.innerWidth
    if (windowWidth >= 1024) {
      setVisiblePages(7)
    } else {
      if (windowWidth >= 640) {
          setVisiblePages(5)
      }else {
       setVisiblePages(3)
    }
    }
  }
  
  useEffect(() => {      
      handleResize()
      window.addEventListener("resize", handleResize)
      return () => {
        window.removeEventListener("resize", handleResize)
      }
  }, [])
  
  function getPagination(currentPage, numbers, visiblePages = 3) {
    const totalPages = numbers.length
    if (totalPages <= visiblePages + 2) return numbers
    const pagination = []
    const half = Math.floor(visiblePages / 2)

    // Рассчитываем границы диапазона
    let leftBoundary = Math.max(1, currentPage - half)
    let rightBoundary = Math.min(totalPages, currentPage + half)

    // Корректируем границы для первых страниц
    if (currentPage <= half) {
      rightBoundary = Math.min(totalPages, visiblePages)
      leftBoundary = 1
    }

    // Корректируем границы для последних страниц
    if (currentPage > totalPages - half) {
      rightBoundary = totalPages
      leftBoundary = Math.max(1, totalPages - visiblePages + 1)
    }

    // Добавляем первые страницы
    if (leftBoundary > 1) {
      pagination.push(1)
      if (leftBoundary > 2) {
        pagination.push("...")
      }
    }

    // Добавляем промежуточные страницы
    for (let i = leftBoundary; i <= rightBoundary; i++) {
      pagination.push(i)
    }

    // Добавляем последние страницы
    if (rightBoundary < totalPages) {
      if (rightBoundary < totalPages - 1) {
        pagination.push("...")
      }
      pagination.push(totalPages)
    }

    return pagination
  }

  const pagination = getPagination(currentPage, numbers,visiblePages)

  return (
    <>
      {pagination.length > 1 ? (
        <div className="pt-2 flex justify-center items-center gap-2 flex-wrap">
          {pagination.map((item, i) => (
            <div key={i}>
              {typeof item === "string" ? (
                <div>
                  <FaEllipsis className="text-2xl text-gray-500" />
                </div>
              ) : (
                <div
                  className={`text-2xl  flex justify-center items-center
         w-10 h-10 border border-blue-500 rounded-full  ${
           currentPage === item
             ? "bg-sb text-gray-100"
             : "bg-inherit text-inherit cursor-pointer hover:bg-gray-50"
         }`}
                  onClick={() => handlePageClick(item)}
                >
                  <p>{item}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : null}
    </>
  )
}
